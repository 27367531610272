<template>
  <div>
    <section class="locations-page">
      <p class="bread-crumb">
        <span class="bread-crumb-contry cursor-pointer underline" @click="$router.go(-2)">{{ country }}</span> /
        <span class="location-bread-crumb cursor-pointer underline" @click="$router.go(-1)">{{ selectedState }}</span> /
        <span class="text-bold">{{ selectedCity }}</span>
      </p>
      <div class="city-locations-wrapper" v-if="selectedCityLocations.length > 0">
        <h1>
          {{ selectedCityLocations.length }}
          <span v-if="selectedCityLocations.length > 1">EV Charging Stations in </span
          ><span v-else>EV Charging Station in </span> <span class="location-bread-crumb">{{ selectedCity }}</span
          >, <span class="location-bread-crumb">{{ selectedState }}</span>
        </h1>
        <div class="locations-page-wrapper">
          <div class="station-location" v-for="(list, index) in selectedCityLocations" :key="index">
            <div>
              <p>
                <a
                  ><strong class="underline cursor-pointer" @click="redirectToMap(list)">{{ list.name }}</strong></a
                >
              </p>
              <p>{{ list.address }}</p>
              <p>{{ list.city }}, {{ list.state }} {{ list.postalCode }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import States from '@/assets/json/us_states_and_territories.json';

export default {
  name: 'city-page',
  components: {},
  metaInfo() {
    return {
      title: this.pageTitle,
      link: [
        {
          rel: 'canonical',
          href: this.canonicalUrl,
        },
      ],
    };
  },
  data() {
    return {
      selectedState: '',
      selectedCity: '',
      pageTitle: '',
      canonicalUrl: '',
      country: 'US',
    };
  },
  async created() {
    this.selectedState = this.$route.params?.state?.replaceAll('-', ' ') ?? '';
    this.selectedCity = this.$route.params?.city?.replaceAll('-', ' ') ?? '';
    this.selectedCity = this.selectedCity.charAt(0).toUpperCase() + this.selectedCity.slice(1);
    this.pageTitle = `EV Charging Stations in ${this.selectedCity}, ${
      this.selectedState.charAt(0).toUpperCase() + this.selectedState.slice(1)
    } | Electrify America`;
    this.canonicalUrl = `https://www.electrifyamerica.com/locations/${this.$route.params?.state}/${this.$route.params?.city}`;
    if (Object.values(this.selectedCityLocations).flat(Infinity).length === 0) {
      await this.$store.dispatch('locations/getAllLocations', this.$router);
    }
    this.$store.commit('locations/setSelectedStateCityLocations', this.$route?.params?.state);
    this.$store.commit('locations/setSelectedCityLocations', this.$route?.params?.city);
  },
  computed: {
    ...mapState('locations', ['selectedCityLocations']),
  },
  methods: {
    stateAbbrivation(state) {
      const stateAbbr = States.filter(
        (p) => p.name.toLowerCase() === state.toLowerCase() || p.abbreviation.toLowerCase() === state.toLowerCase()
      );
      return stateAbbr[0]?.abbreviation.toLowerCase();
    },
    redirectToMap(list) {
      const route = `/locate-charger/${this.stateAbbrivation(list.state)}/${list.city
        .replace(/\s/g, '-')
        .toLowerCase()}/${list.address
        .replace(/\s/g, '-')
        .replace(/&/g, '&amp;')
        .replace(/[<>#%|'’.]/g, '')
        .toLowerCase()}/${list.id}/`;
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.locations-page {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  min-height: calc(100vh - 550px);
  &-wrapper {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr;
    ul {
      padding-left: 8px;
    }
    li {
      list-style-type: none;
      display: inline-block;
      cursor: pointer;
    }
  }
  .bread-crumb {
    margin-left: 10px;
    font-size: 14px;
  }

  .city-locations-wrapper {
    margin-top: 18px;
    margin-left: 6px;
  }
  .location-bread-crumb {
    text-transform: capitalize;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .text-bold {
    font-weight: 500;
  }
  .station-location p {
    line-height: 1;
  }
}
@media screen and (max-width: 768px) {
  .locations-page {
    padding: 16px;
    &-wrapper {
      grid-template-columns: 1fr;
    }
  }
}
</style>
